import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Button,
  FormControl,
  Typography,
  FormGroup,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Checkbox,
  Radio,
  Backdrop,
  CircularProgress,
  Divider,
  RadioGroup,
  FormControlLabel,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  getFormMembersDetails_V1,
  getPreviewDetail,
  getTemplateDetail_V1,
  submitForm,
} from "src/DAL/Forms/forms";
import AvatarGalleryPreview from "./component/imageViewOnLengthPreview";
import { makeStyles } from "@mui/styles";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import CustomPopover from "./CustomPopover";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DetailsFormInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const location = useLocation();
  const { state } = useLocation();
  const param = useParams();
  const classes = useStyles();
  const user_id = param.user_id;
  const _id = param.slug;
  const [templateTitle, setTemplateTitle] = useState("");
  // const anchorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [formData, setFormData] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [privateNote, setPrivateNote] = useState("");
  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeText = (e, index) => {
    questions[index].answer = e.target.value;
    setQuestions([...questions]);
  };
  const handleChangeDescription = (e, index) => {
    questions[index].description = e.target.value;
    setQuestions([...questions]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let noAnswer = false;
    let question = [];
    questions.map((item, index) => {
      if (item?.type == "rating" && item?.answer == "") {
        question.push(index + 1);
        // enqueueSnackbar(`Please Answer the Question:${index + 1}`, {
        //   variant: "error",
        // });
        noAnswer = true;
      }
    });
    if (!noAnswer) {
      let postData = {
        questions: questions,
      };
      console.log(postData, _id, "postData");

      try {
        const res = await submitForm(_id, postData);
        if (res.code === 200) {
          navigate(-1);
          enqueueSnackbar(res.message, { variant: "success" });
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      } finally {
        // setLoading(false);
      }
    } else {
      enqueueSnackbar(`Please Answer the Question:${question[0]}`, {
        variant: "error",
      });

      return;
    }
  };

  // const getFormDetails = async () => {
  //   setIsLoading(true);
  //   const type = "individual";
  //   const result = await getTemplateDetail_V1(_id, user_id, type);
  //   if (result.code === 200) {
  //     setTemplateTitle(state);
  //     setFeedbackList(result?.data);
  //     if (page_type === "forms") {
  //       setQuestions(result?.form?.questions);
  //     }
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };

  const handleFeedback = () => {
    setOpenFeedback(true);
  };

  const getFormDetails = async () => {
    setIsLoading(true);
    const result = await getTemplateDetail_V1(_id, user_id, "individual");
    console.log(result, "getTemplateDetail_V1");
    if (result.code === 200) {
      setTemplateTitle(state);
      setFormData(result?.form);
      setFeedbackList(result?.data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      navigate(`/forms`);
    }
  };

  const getFormMembersDetails = async () => {
    setIsLoading(true);
    const result = await getFormMembersDetails_V1(_id, user_id);
    if (result.code === 200) {
      setQuestions(result?.data?.questions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFormMembersDetails();
    getFormDetails();
  }, [state]);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <Backdrop
        invisible="true"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        mb={4.5}
      >
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-baseline">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}
            >
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
            <div className="ms-2">
              <h2 className="mb-0">{formData?.title}</h2>
              <Typography
                className="text-start w-100"
                sx={{ fontWeight: "500", fontSize: 16, opacity: 0.6 }}
              >
                {formData?.description}
              </Typography>
            </div>
          </div>
          {feedbackList.length > 0 && (
            <div>
              <Button variant="contained" onClick={handleFeedback}>
                Feedback
              </Button>
            </div>
          )}
        </div>
      </Stack>
      <>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            justifyContent="space-between"
            mb={4.5}
          >
            {questions &&
              questions.map((item, index) => {
                return (
                  <Card
                    className="w-100"
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      mb: 1,
                      width: { sm: "100%", md: "100%" },
                      margin: "auto",
                      p: 2,
                      backgroundColor: "",
                      borderRadius: 0.5,
                      position: "relative",
                    }}
                  >
                    {item?.required && (
                      <Chip
                        label={"Required"}
                        variant="contained"
                        color={"error"}
                        sx={{
                          fontSize: "10px",
                          height: "auto",
                          width: "auto",
                          position: "absolute",
                          top: 8,
                          left: 16,
                        }}
                      />
                    )}
                    <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                        mt={1}
                      >
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                              >
                                Q{index + 1}.
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: 14, mr: 1 }}
                              >
                                {item?.title}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        {item?.type == "multiple_choice" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={item?.answer}
                                onChange={(e) => {
                                  questions[index].answer = e.target.value;
                                  setQuestions([...questions]);
                                }}
                              >
                                {item?.options.map((item) => {
                                  return (
                                    <Grid item md={12}>
                                      <FormControlLabel
                                        value={item}
                                        disabled
                                        control={
                                          <Radio
                                            sx={{
                                              "&.Mui-disabled": {
                                                color: "#212B36",
                                              },
                                              "&.Mui-checked": {
                                                color: "#0096FF",
                                              },
                                            }}
                                          />
                                        }
                                        label={
                                          <span style={{ color: "#212B36" }}>
                                            {item}
                                          </span>
                                        }
                                      />
                                    </Grid>
                                  );
                                })}{" "}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "multiple_select" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <FormGroup>
                                {item?.options.map((option) => (
                                  <Grid item md={12} key={option}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          sx={{
                                            "&.Mui-disabled": {
                                              color: "#212B36",
                                            },
                                            "&.Mui-checked": {
                                              color: "#0096FF",
                                            },
                                          }}
                                          disabled
                                          checked={questions[
                                            index
                                          ]?.multiple_answers.includes(option)}
                                        />
                                      }
                                      label={
                                        <span style={{ color: "#212B36" }}>
                                          {option}
                                        </span>
                                      }
                                    />
                                  </Grid>
                                ))}
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "image" ? (
                          <Grid item md={12} xs={12}>
                            <div className="d-flex align-items-center mb-2">
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    mr: 1,
                                  }}
                                >
                                  {item?.options[0]}
                                </Typography>
                              </div>
                              <AvatarGalleryPreview
                                length={item?.options_count}
                                Index={index}
                                questions={questions}
                                Image={item?.multiple_answers}
                                setQuestions={setQuestions}
                              />
                            </div>
                          </Grid>
                        ) : item?.type == "free_text" ? (
                          <Grid item md={12} xs={12}>
                            <Typography
                              sx={{
                                fontSize: 14,

                                mb: 0,
                                width: "100%",
                              }}
                            >
                              {item?.answer && (
                                <span>
                                  <b>
                                    <i>Answer: </i>
                                  </b>
                                </span>
                              )}
                              {item?.answer ? item?.answer : "No Answer"}
                            </Typography>
                          </Grid>
                        ) : (
                          item?.type == "signature" && (
                            <Grid item md={12} xs={12}>
                              {item?.answer ? (
                                <img
                                  key={index}
                                  src={item?.answer}
                                  alt={`Signature ${index + 1}`}
                                />
                              ) : (
                                <TextField
                                  id="outlined-multiline-static"
                                  multiline
                                  disabled
                                  rows={4}
                                  sx={{
                                    width: "100%",
                                    "& fieldset": {
                                      top: 0,
                                    },
                                    "& legend": {
                                      display: "none",
                                    },
                                  }}
                                />
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Card>
                );
              })}
          </Stack>
        </form>
      </>
      <CustomPopover
        isOpenPop={openFeedback}
        isClosePop={setOpenFeedback}
        title={`Feedback`}
        componentToPassDown={
          <>
            <div className="container">
              <div className="row">
                {feedbackList.length > 0 ? (
                  feedbackList?.map((item, index) => {
                    return (
                      <div className="col-12 mt-3">
                        <Card
                          className="w-100"
                          sx={{
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            mb: 1,
                            width: { sm: "100%", md: "100%" },
                            margin: "auto",
                            p: 2,
                            backgroundColor: "",
                            borderRadius: 0.5,
                          }}
                        >
                          <Accordion defaultExpanded={index === 0}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel${index}-content`}
                              id={`panel${index}-header`}
                            >
                              <p className="mb-0">{index + 1} : Feedback</p>{" "}
                              <div
                                className="ms-auto text-muted"
                                onClick={(e) => e.stopPropagation()}
                              >
                                {moment(item?.createdAt).format(
                                  "DD MMM YYYY [at] hh:mm A"
                                )}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.feedback_content,
                                }}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <RecordNotFound title="Feedback" />
                )}
              </div>
            </div>
          </>
        }
      />
    </div>
  );
}
